<template>
<fragment>
    <loading v-if="isLoading"/>
    <div class="content">
        <div class="bg"></div>
        <div class="header pb-1">
            <div class="d-flex my-2 align-items-center">
                <div class="">
                    <img :src="shop.shop_img_url ? shop.shop_img_url : defaultImgLogo" class="rounded-circle" width="56" height="56" style="object-fit: cover;">
                </div>
                <div class=" pl-3 pr-1 ">
                    <div class="fc-1 tx-20 fw-500 cuttext">{{shop.shop_name ? shop.shop_name : 'STORE NAME'}}</div>
                </div>
                <div class="ml-auto text-right align-self-end">
                    <img src="@/assets/images/logo.png" width="65" height="22">
                    <div class="tx-11 lh-16p">ติดต่อเรา <a style="color: rgba(0,0,0,0.75)" href="https://lin.ee/edopWvU">@yellow-idea</a></div>
                </div>
            </div>
        </div>
        <div class="px-4 pt-2">
            <div id="form-receipt" v-if="formData.point_step === 'POINT'">
                <div class="form-group">
                    <label><span class="fc-4">*</span> ยอดรวมใบเสร็จ</label>
                    <input
                        type="tel"
                        class="form-control form-custom-2 input"
                        v-model="formData.total_price"
                        @keypress="onPriceInput"
                        style="font-size: 18px;"
                    >
                </div>
                <div class="form-group">
                    <label><span class="fc-4">*</span> เลขที่ใบเสร็จอ้างอิง</label>
                    <div class="d-flex">
                        <input
                            type="text"
                            class="form-control form-custom-2 input mr-2"
                            v-model="formData.receipt_no"
                            :class="{'is-invalid': formData.verify_dirty && !formData.verified , 'is-valid': formData.verified && formData.receipt_no }"
                            style="font-size: 18px;"
                        >
                        <button type="button" class="btn btn-success" @click="verifyReceiptNumber" :disabled="!formData.receipt_no">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" viewBox="0 0 24 24" width="24" height="24"><path d="M3.5 3.75a.25.25 0 01.25-.25h13.5a.25.25 0 01.25.25v10a.75.75 0 001.5 0v-10A1.75 1.75 0 0017.25 2H3.75A1.75 1.75 0 002 3.75v16.5c0 .966.784 1.75 1.75 1.75h7a.75.75 0 000-1.5h-7a.25.25 0 01-.25-.25V3.75z"></path><path d="M6.25 7a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5zm-.75 4.75a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75zm16.28 4.53a.75.75 0 10-1.06-1.06l-4.97 4.97-1.97-1.97a.75.75 0 10-1.06 1.06l2.5 2.5a.75.75 0 001.06 0l5.5-5.5z"></path></svg>
                        </button>
                    </div>
                    <div class="text-danger mt-1" v-if="formData.verify_dirty && !formData.verified">เลขที่ใบเสร็จซ้ำ</div>
                </div>
                <div class="list-item">
                    <div class="d-flex item" v-for="(product, index) in formData.products" :key="index">
                        <div class="form-group flex-fill flex-grow-1 pr-3">
                            <label>รายการที่ <span class="num">{{index + 1}}</span></label>
                            <select class="form-control form-custom-2 select2 input" :class="{'place': !product.product_id}" v-model="product.product_id" style="font-size: 18px;">
                                <option value="" selected="" disabled>ระบุสินค้า</option>
                                <option :value="item.id" v-for="(item, item_index) in items" :key="item_index">{{item.name}}</option>
                            </select>
                        </div>
                        <div class="form-group Balance">
                            <label>ยอด</label>
                            <div class="d-flex">
                                <input
                                    type="tel"
                                    class="form-control form-custom-2 text-center validateNumber input mr-2"
                                    v-model="product.price"
                                    style="width: 70px;font-size: 18px;"
                                    placeholder="0"
                                    @keypress="onPriceInput"
                                >
                                <div style="width: 41px; height: 41px">
                                    <button type="button" class="btn btn-danger"  @click="deleteProduct(product.id)" style="width: 100%; height: 100%;padding: 0;" v-if="index > 0">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF"  viewBox="0 0 24 24" width="20" height="20"><path fill-rule="evenodd" d="M16 1.75V3h5.25a.75.75 0 010 1.5H2.75a.75.75 0 010-1.5H8V1.75C8 .784 8.784 0 9.75 0h4.5C15.216 0 16 .784 16 1.75zm-6.5 0a.25.25 0 01.25-.25h4.5a.25.25 0 01.25.25V3h-5V1.75z"></path><path d="M4.997 6.178a.75.75 0 10-1.493.144L4.916 20.92a1.75 1.75 0 001.742 1.58h10.684a1.75 1.75 0 001.742-1.581l1.413-14.597a.75.75 0 00-1.494-.144l-1.412 14.596a.25.25 0 01-.249.226H6.658a.25.25 0 01-.249-.226L4.997 6.178z"></path><path d="M9.206 7.501a.75.75 0 01.793.705l.5 8.5A.75.75 0 119 16.794l-.5-8.5a.75.75 0 01.705-.793zm6.293.793A.75.75 0 1014 8.206l-.5 8.5a.75.75 0 001.498.088l.5-8.5z"></path></svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-2 mb-3">
                    <span class="tx-29 lh-0 ">+ </span><button type="button" class="btn btn-add-item mt-n1" @click="addItem">เพิ่มรายการ</button>
                </div>
                <!-- <upload-image
                    label="อัพโหลดใบเสร็จ"
                    :size="10"
                    v-model="formData.img_url"
                /> -->
                <div class="form-group pt-1 pb-5">
                    <label class="tx-22 fc-4">คะแนนที่ได้รับ</label>
                    <div class="d-flex">
                        <div class="display-label text-center" style="color: #1ED700;">{{parseInt(formData.point ? formData.point : 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
                        <div class="align-self-center ml-2 tx-22 fc-4">
                            คะแนน
                        </div>
                    </div>
                </div>
                <div id="checkheight" class=""></div>
                <div class="footer " id="footer-bottom">
                    <div class="text-center ">
                        <button type="submit" class="btn btn-next w-100" :disabled="!formData.is_valid" @click="onConfirmClick">ถัดไป</button>
                    </div>
                </div>
            </div>
            <div style="margin-bottom: 66px" v-else-if="formData.point_step === 'CONFIRM'">
                <div class="text-center fc-3 tx-24 mb-2">ยืนยันการให้คะแนน</div>
				<div class="" id="form-receipt">
                    <div class="tx-20" style="color: #4D4D4D; margin-bottom: 6px">คุณ {{customer.user_name}}</div>
                    <div class="tx-20" style="color: #4D4D4D">เบอร์โทร {{customer.phone_number}}</div>
                    <div class="line" />
					<div class="form-group">
						<label>ยอดรวมใบเสร็จ</label>
                        <div class="display-label" style="color: #000000;">{{parseFloat(formData.total_price ? formData.total_price : 0).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
					</div>
					<div class="form-group pt-2 mb-3">
						<label class="tx-22 fc-4">คะแนนที่ได้รับ</label>
						<div class="d-flex">
                            <div class="display-label text-center" style="color: #1ED700;">{{parseInt(formData.point ? formData.point : 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</div>
							<div class="align-self-center ml-2 tx-22 fc-4">
								คะแนน
							</div>
						</div>
					</div>
					<div class="tx-22 fc-3">ให้คะแนนโดย:  <span class="tx-22">คุณ {{profile.first_name ? profile.first_name :'[First Name]'}}</span></div>
					<div class="tx-22 fc-3">วันที่: {{moment().format("DD/MM/YYYY HH:mm")}} น.</div>
					<div id="checkheight"></div>
					<div class="footer " id="footer-bottom">
						<div class=" d-flex justify-content-center mt-4 ">
							<button type="submit" class="btn btn-cancle-2 mx-2 mb-2" @click="formData.point_step = 'POINT'">&lt; แก้ไขข้อมูล</button>
							<button type="submit" class="btn btn-confirm mx-2 mb-2"  @click="onSubmit">ถัดไป</button>
						</div>
					</div>
				</div>
            </div>
            <div v-else-if="formData.point_step === 'COMPLETE'">
                <div class="needs-validation px-4 error" id="form-login" novalidate> 
                    <div class="px-3 d-flex justify-content-center box-input">
                        <div class="align-self-center text-center">
                            <img src="@/assets/images/correct-y.png" width="119" height="119" class="mb-5">
                            <div class="fc-3 tx-29 lh-21p">ให้คะแนนเรียบร้อย</div>
                        </div>
                    </div>
                    <div class="bottom px-3 my-3">
                        <div class="text-center pb-2">
                            <button class="btn btn-login w-100" @click="onCloseClick">ปิดหน้าต่างนี้</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal ระบุยอด -->
    <div class="modal fade" id="modal-error" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true">
        <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body px-2">
                    <div class="fc-3 tx-24 fw-500 text-center mb-3 mt-3">กรุณาระบุยอดของรายการสินค้า</div>
                    <div class="d-flex flex-wrap justify-content-center mt-4 mb-1">
                        <button class="btn btn-confirm mx-1" data-dismiss="modal" aria-label="Close">ตกลง</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</fragment>
</template>

<script>
import { uuid } from "vue-uuid"
import moment from 'moment'

import { requestUserPoint, requestReceiptVerify } from '@/apis/staff'
import { requestLogActivity } from '@/apis/apis'

export default {
    props: ['profile', 'shop', 'items', 'customer'],
    data() {
        // eslint-disable-next-line no-undef
        const profile = $cookies.get("STAFF_PROFILE_DATA")
        return {
            moment,
            isLoading: false,
            userProfile : profile || {},
            defaultImg: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
            defaultImgLogo: require('@/assets/images/shop-mockup.png'),
            formData: {
                is_valid: false,
                total_price: null,
                point_step: 'POINT', //POINT, CONFIRM, COMPLETE
                receipt_no: '',
                img_url: '',
                verify_dirty: false,
                point: null,
                verified: false,
                products: [
                    {
                        id : uuid.v4(),
                        product_id: '',
                        price: null,
                    }
                ],
            },
        }
    },
    mounted() {
        this.logActivity('User point')
    },
    updated() {
        if(this.formData.point_step === 'CONFIRM') {
            this.logActivity('User point confirm')
        }
        else if(this.formData.point_step === 'COMPLETE') {
            this.logActivity('User point complete')
        }
    },
    watch: {
        'formData': {
            handler(val){
                if (!val.total_price ||
                    !this.formData.verified) {
                    this.formData.is_valid = false
                    return false
                }
                else {
                    this.formData.is_valid = true
                }
            },
            deep: true
        },
        'formData.total_price': function() {
            const point = this.formData.total_price / this.shop.sale_amount
            this.formData.point = Math.floor(point)
        }
    },
    methods: {
        onPriceInput(event) {
            const key = window.event ? event.keyCode : event.which
            if (event.keyCode === 8 || event.keyCode === 46) {
		        return true
            }
            else if ( key < 48 || key > 57 ) {
               event.preventDefault()
            }
            else {
                return true
		    }
        },
        async verifyReceiptNumber() {
            this.isLoading = true
            this.formData.verify_dirty = true
            if (!this.formData.receipt_no) {
                this.isLoading = false
                return false
            }
            else {
                await requestReceiptVerify({
                    bot_id: this.userProfile.bot_id,
                    formData : {
                        receipt_no: this.formData.receipt_no,
                    } ,
                    mid : this.userProfile.mid,
                    role: 'employee',
                    cbSuccess : res => {
                        this.formData.verified = res.data.data.result
                        this.isLoading = false
                    } ,
                    cbError : () => {
                        this.isLoading = false
                    }
                })
            }
        },
        addItem() {
            const itemDefault = {
                id : uuid.v4(),
                product_id: '',
                price: null,
            }
            this.formData.products.push(itemDefault)
        },
        deleteProduct(id) {
            this.formData.products = [...this.formData.products].filter(product => product.id !== id)
        },
        async onSubmit() {
            this.isLoading = true
            await requestUserPoint({
                bot_id: this.userProfile.bot_id,
                formData : {
                    mid: this.userProfile.user_mid,
                    submitter_id: this.userProfile.mid,
                    submitter_type: 'employee',
                    submitter_name: this.userProfile.display_name,
                    total_price: this.formData.total_price,
                    point: this.formData.point,
                    receipt_no: this.formData.receipt_no,
                    products: this.formData.products.filter(product => product.product_id && product.price),
                    img_url: this.formData.img_url,
                } ,
                mid : this.userProfile.mid,
                role: 'employee',
                cbSuccess : () => {
                    this.formData.point_step = 'COMPLETE',
                    this.isLoading = false
                } ,
                cbError : () => {
                    this.isLoading = false
                }
            })
        },
        onCloseClick() {
            // eslint-disable-next-line no-undef
            liff.closeWindow()
        },
        onConfirmClick() {
            if (this.formData.products.find(item => item.product_id && !item.price)) {
                this.formData.is_valid = false
                window.$('#modal-error').modal()
                return false
            }
            else {
                this.formData.point_step = 'CONFIRM'
            }
        },
        async logActivity(activity) {
            await requestLogActivity({
                formData: {
                    mid: this.userProfile.mid,
                    bot_id: this.userProfile.bot_id,
                    activity,
                },
                role: 'user',
                cbSuccess : () => {} ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                }
            })
        }
    }
}
</script>
