<template>
<fragment>
    <loading v-if="isLoading"/>
    <div>
        <div v-if="errorMessage!==null">{{ errorMessage }}</div>
        <home
            v-else-if="pageType === 'HOME'"
            :user="userProfile"
            :profile="displayStaffProfile"
            :shop="displayShop"
            :items="displayItems"
            :customer="displayUser"
        />
        
    </div>
</fragment>
</template>

<script>
import Home from '@/components/staff/Home'

import { requestUserInfo } from '@/apis/user'
import { requestStaffInfo } from '@/apis/staff'
import { requestShopInfo, requestItemsList } from '@/apis/shop'

export default {
    components: {
        Home,
    },
    data() {
        // eslint-disable-next-line no-undef
        const profile = $cookies.get("STAFF_PROFILE_DATA")
        return {
            userProfile : profile || {},
            pageType: null,
            isLoading : false ,
            errorMessage : null,
            displayStaffProfile: {},
            displayShop: {},
            displayItems: [],
            displayUser: {},
        }
    },
    methods: {
        async onLoad() {
            this.isLoading = true
            await requestStaffInfo({
                bot_id: this.userProfile.bot_id,
                mid : this.userProfile.mid,
                role: 'employee',
                cbSuccess : res => {
                    this.displayStaffProfile = res.data.data
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                    this.isLoading = false
                }
            })
            await requestShopInfo({
                bot_id: this.userProfile.bot_id,
                mid: this.userProfile.mid,
                role: 'employee',
                cbSuccess : res => {
                    this.displayShop = res.data.data
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                    this.isLoading = false
                }
            })
            await requestItemsList({
                bot_id: this.userProfile.bot_id,
                formData : {
                    sort: "",
                    order: "asc",
                    offset: 0,
                    limit: 1000,
                    filter: {},
                },
                mid: this.userProfile.mid,
                role: 'employee',
                cbSuccess : res => {
                    this.displayItems = res.data.rows
                    this.isLoading = false
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                    this.isLoading = false
                }
            })
            await requestUserInfo({
                bot_id: this.userProfile.bot_id,
                mid: this.userProfile.mid,
                role: 'employee',
                cbSuccess : res => {
                    this.displayUser = res.data.data
                    this.isLoading = false
                } ,
                cbError : (_e , msg) => {
                    this.errorMessage = msg
                    this.isLoading = false
                }
            })
            this.pageType = 'HOME'
        },
    },
    mounted() {
        this.onLoad()
    },
}
</script>